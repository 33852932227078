var render = function () {
  var _vm$monthlyReportSumm, _vm$monthlyReportSumm2, _vm$monthlyReportSumm3, _vm$monthlyReportSumm4, _vm$monthlyReportSumm5, _vm$monthlyReportSumm6, _vm$monthlyReportSumm7, _vm$monthlyReportSumm8, _vm$monthlyReportSumm9, _vm$monthlyReportSumm10, _vm$monthlyReportSumm11, _vm$monthlyReportSumm12, _vm$monthlyReportSumm13, _vm$monthlyReportSumm14, _vm$monthlyReportSumm15, _vm$monthlyReportSumm16, _vm$monthlyReportSumm17, _vm$monthlyReportSumm18, _vm$monthlyReportSumm19, _vm$monthlyReportSumm20, _vm$monthlyReportSumm21, _vm$monthlyReportSumm22, _vm$monthlyReportSumm23, _vm$monthlyReportSumm24, _vm$monthlyReportSumm25, _vm$monthlyReportSumm26, _vm$monthlyReportSumm27;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-Roboto"
  }, [_c('Parent'), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "d-flex pt-6 pl-4"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeMonth('prev');
      }
    }
  }, [_c('v-icon', [_vm._v("$purpleArrowLeft")])], 1), _c('div', {
    staticClass: "font-18px mx-5 primary--text"
  }, [_vm._v(" " + _vm._s(_vm.dateSlashFormatted) + " ")]), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeMonth('next');
      }
    }
  }, [_c('v-icon', [_vm._v("$purpleArrowRight")])], 1)], 1)]), _c('div', {
    staticClass: "mt-5 header-table"
  }, [_c('v-simple-table', {
    staticClass: "table-text"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, _vm._l(_vm.monthlyDataNames, function (item) {
    return _c('td', {
      key: item,
      staticClass: "font-12px table-column"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)])]), _c('v-simple-table', {
    staticClass: "result-table pb-2"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, [_c('td', {
    staticClass: "font-12px result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$monthlyReportSumm = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm === void 0 ? void 0 : (_vm$monthlyReportSumm2 = _vm$monthlyReportSumm.sales) === null || _vm$monthlyReportSumm2 === void 0 ? void 0 : _vm$monthlyReportSumm2.sales) || 0)) + " ")]), _c('td', {
    staticClass: "font-12px result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$monthlyReportSumm3 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm3 === void 0 ? void 0 : (_vm$monthlyReportSumm4 = _vm$monthlyReportSumm3.sales) === null || _vm$monthlyReportSumm4 === void 0 ? void 0 : _vm$monthlyReportSumm4.goal) || 0)) + " ")]), _c('td', {
    staticClass: "font-12px result-column"
  }, [_c('div', {
    "class": ((_vm$monthlyReportSumm5 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm5 === void 0 ? void 0 : (_vm$monthlyReportSumm6 = _vm$monthlyReportSumm5.sales) === null || _vm$monthlyReportSumm6 === void 0 ? void 0 : _vm$monthlyReportSumm6.goal_percentage) > 100 ? 'text-green' : 'text-red'
  }, [_vm._v(" " + _vm._s((_vm$monthlyReportSumm7 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm7 === void 0 ? void 0 : (_vm$monthlyReportSumm8 = _vm$monthlyReportSumm7.sales) === null || _vm$monthlyReportSumm8 === void 0 ? void 0 : _vm$monthlyReportSumm8.goal_percentage) + " % ")])]), _c('td', {
    staticClass: "font-12px result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$monthlyReportSumm9 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm9 === void 0 ? void 0 : (_vm$monthlyReportSumm10 = _vm$monthlyReportSumm9.sales) === null || _vm$monthlyReportSumm10 === void 0 ? void 0 : _vm$monthlyReportSumm10.cash_sales) || 0)) + " ")]), _c('td', {
    staticClass: "font-12px result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$monthlyReportSumm11 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm11 === void 0 ? void 0 : (_vm$monthlyReportSumm12 = _vm$monthlyReportSumm11.costs) === null || _vm$monthlyReportSumm12 === void 0 ? void 0 : _vm$monthlyReportSumm12.costs) || 0)) + " ")]), _c('td', {
    staticClass: "font-12px result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$monthlyReportSumm13 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm13 === void 0 ? void 0 : _vm$monthlyReportSumm13.profit_or_loss) || 0)) + " ")]), _c('td', {
    staticClass: "font-12px result-column"
  }, [_c('div', {
    "class": ((_vm$monthlyReportSumm14 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm14 === void 0 ? void 0 : (_vm$monthlyReportSumm15 = _vm$monthlyReportSumm14.costs) === null || _vm$monthlyReportSumm15 === void 0 ? void 0 : _vm$monthlyReportSumm15.food_cost_percentage) > 100 ? 'text-green' : 'text-red'
  }, [_vm._v(" " + _vm._s((_vm$monthlyReportSumm16 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm16 === void 0 ? void 0 : (_vm$monthlyReportSumm17 = _vm$monthlyReportSumm16.costs) === null || _vm$monthlyReportSumm17 === void 0 ? void 0 : _vm$monthlyReportSumm17.food_cost_percentage) + "% ")]), _c('div', {
    staticClass: "font-12px text-bbb"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$monthlyReportSumm18 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm18 === void 0 ? void 0 : (_vm$monthlyReportSumm19 = _vm$monthlyReportSumm18.costs) === null || _vm$monthlyReportSumm19 === void 0 ? void 0 : _vm$monthlyReportSumm19.foodstuff_cost)) + " ")])]), _c('td', {
    staticClass: "font-12px result-column"
  }, [_c('div', {
    "class": ((_vm$monthlyReportSumm20 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm20 === void 0 ? void 0 : (_vm$monthlyReportSumm21 = _vm$monthlyReportSumm20.costs) === null || _vm$monthlyReportSumm21 === void 0 ? void 0 : _vm$monthlyReportSumm21.labor_cost_percentage) > 100 ? 'text-green' : 'text-red'
  }, [_vm._v(" " + _vm._s((_vm$monthlyReportSumm22 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm22 === void 0 ? void 0 : (_vm$monthlyReportSumm23 = _vm$monthlyReportSumm22.costs) === null || _vm$monthlyReportSumm23 === void 0 ? void 0 : _vm$monthlyReportSumm23.labor_cost_percentage) + "% ")]), _c('div', {
    staticClass: "font-12px text-bbb"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((_vm$monthlyReportSumm24 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm24 === void 0 ? void 0 : (_vm$monthlyReportSumm25 = _vm$monthlyReportSumm24.costs) === null || _vm$monthlyReportSumm25 === void 0 ? void 0 : _vm$monthlyReportSumm25.labor_cost)) + " ")])]), _c('td', {
    staticClass: "font-12px result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$monthlyReportSumm26 = _vm.monthlyReportSummary) === null || _vm$monthlyReportSumm26 === void 0 ? void 0 : (_vm$monthlyReportSumm27 = _vm$monthlyReportSumm26.sales) === null || _vm$monthlyReportSumm27 === void 0 ? void 0 : _vm$monthlyReportSumm27.average_customer_spend) || 0)) + " ")])])])])], 1)]), _c('v-card', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "pt-5 ml-4 pb-6"
  }, [_c('v-card', {
    attrs: {
      "color": "#525252",
      "depressed": "",
      "flat": "",
      "height": "29px",
      "width": "52px"
    }
  }, [_c('div', {
    staticClass: "font-16px white--text pl-2 pt-1"
  }, [_vm._v(" 売上 ")])])], 1), _c('div', {
    staticClass: "pt-5 ml-3"
  }, [_c('v-btn-toggle', {
    staticClass: "d-flex toggle-button",
    attrs: {
      "borderless": "",
      "dense": ""
    },
    model: {
      value: _vm.selectedSalesTypeToggle,
      callback: function callback($$v) {
        _vm.selectedSalesTypeToggle = $$v;
      },
      expression: "selectedSalesTypeToggle"
    }
  }, _vm._l(_vm.salesTypeToggleButtonNames, function (item, index) {
    return _c('v-btn', {
      key: index,
      staticClass: "text-a1 selected-btn mx-3",
      attrs: {
        "height": "26px",
        "color": "transparent",
        "rounded": "",
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  }), 1)], 1)]), _c('div', {
    staticClass: "mt-5 mr-7"
  }, [_vm.showEarningsTable ? _c('v-icon', {
    attrs: {
      "size": "12"
    },
    on: {
      "click": function click($event) {
        _vm.showEarningsTable = !_vm.showEarningsTable;
      }
    }
  }, [_vm._v("$arrowUp")]) : _vm._e(), !_vm.showEarningsTable ? _c('v-icon', {
    attrs: {
      "size": "12"
    },
    on: {
      "click": function click($event) {
        _vm.showEarningsTable = !_vm.showEarningsTable;
      }
    }
  }, [_vm._v("$arrowDown")]) : _vm._e()], 1)]), _vm.showEarningsTable ? _c('Sales', {
    attrs: {
      "selected-category": _vm.selectedSalesTypeToggle
    }
  }) : _vm._e()], 1), _c('v-card', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex pt-5 ml-4 pb-5"
  }, [_c('v-card', {
    attrs: {
      "color": "#525252",
      "depressed": "",
      "flat": "",
      "height": "29px",
      "width": "64px"
    }
  }, [_c('div', {
    staticClass: "font-16px white--text pl-2 pt-1"
  }, [_vm._v(" コスト ")])])], 1), _c('div', {
    staticClass: "mt-6 mr-7"
  }, [_vm.showCostTable ? _c('v-icon', {
    attrs: {
      "size": "12"
    },
    on: {
      "click": function click($event) {
        _vm.showCostTable = !_vm.showCostTable;
      }
    }
  }, [_vm._v("$arrowUp")]) : _vm._e(), !_vm.showCostTable ? _c('v-icon', {
    attrs: {
      "size": "12"
    },
    on: {
      "click": function click($event) {
        _vm.showCostTable = !_vm.showCostTable;
      }
    }
  }, [_vm._v("$arrowDown")]) : _vm._e()], 1)]), _vm.showCostTable ? [_c('div', {
    staticClass: "gray-divider mt-0"
  }), _c('Cost')] : _vm._e()], 2), _c('v-card', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex pt-5 ml-4 pb-5"
  }, [_c('v-card', {
    attrs: {
      "color": "#525252",
      "depressed": "",
      "flat": "",
      "height": "29px",
      "width": "96px"
    }
  }, [_c('div', {
    staticClass: "font-16px white--text pl-2 pt-1"
  }, [_vm._v(" 食材原価率 ")])])], 1), _c('div', {
    staticClass: "mt-6 mr-7"
  }, [_vm.showIngredientCostRate ? _c('v-icon', {
    attrs: {
      "size": "12"
    },
    on: {
      "click": function click($event) {
        _vm.showIngredientCostRate = !_vm.showIngredientCostRate;
      }
    }
  }, [_vm._v("$arrowUp")]) : _vm._e(), !_vm.showIngredientCostRate ? _c('v-icon', {
    attrs: {
      "size": "12"
    },
    on: {
      "click": function click($event) {
        _vm.showIngredientCostRate = !_vm.showIngredientCostRate;
      }
    }
  }, [_vm._v("$arrowDown")]) : _vm._e()], 1)]), _vm.showIngredientCostRate ? _c('IngredientCostRate') : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }