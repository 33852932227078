var render = function () {
  var _vm$getShopMonthlyRep, _vm$salesReport;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "earnings-table"
  }, [_c('v-simple-table', {
    staticClass: "table-text"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, _vm._l(_vm.tableHeader, function (item) {
    return _c('td', {
      key: item,
      staticClass: "font-12px table-column"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)])]), _c('v-simple-table', {
    staticClass: "earning-result-table pb-2"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, [_c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s((_vm$getShopMonthlyRep = _vm.getShopMonthlyReport) === null || _vm$getShopMonthlyRep === void 0 ? void 0 : _vm$getShopMonthlyRep.business_days) + "日 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm.getValue("number_of_total_customer") || 0) + "人 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm.getValue("number_of_customer_store") || 0) + "人 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm.getValue("number_of_total_group") || 0) + "組 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm.getValue("number_of_group_store") || 0) + "組 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("average_customer_spend") || 0)) + " ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("average_in_shop_customer_spend") || 0)) + " ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("sales") || 0)) + " ")]), _vm.selectedCategory !== 'all' ? [_c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm.getValue("sunny_days") || 0) + " ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm.getValue("cloudy_days") || 0) + " ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm.getValue("rainy_days") || 0) + " ")])] : _vm._e()], 2)])]), _c('div', {
    staticClass: "earnings-card mt-6 ml-4 d-flex align-center font-14px"
  }, [_c('div', {
    staticClass: "ml-3 text-light-grey"
  }, [_vm._v("売上")]), _c('div', {
    staticClass: "ml-16 pl-3 text-light-grey"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("sales") || 0)) + " ")]), _vm.selectedCategory === 'all' ? [_c('div', {
    staticClass: "ml-14 light-gray-text"
  }, [_vm._v("( 現金売上 )")]), _c('div', {
    staticClass: "ml-5 light-gray-text"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$salesReport = _vm.salesReport) === null || _vm$salesReport === void 0 ? void 0 : _vm$salesReport.cash_sales) || 0)) + " ")])] : _vm._e()], 2), _c('div', {
    staticClass: "mt-5 pb-10 ml-4 d-flex align-center font-14px text-light-grey"
  }, [_c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("フード")]), _c('div', {
    staticClass: "ml-15"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("food_sales") || 0)) + " (" + _vm._s(_vm.getValue("food_sales_percentage") || 0) + "%) ")])]), _c('div', {
    staticClass: "d-flex mt-6"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("テイクアウト")]), _c('div', {
    staticClass: "ml-5"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("takeout_unit_sales") || 0)) + " (" + _vm._s(_vm.getValue("takeout_sales_percentage") || 0) + "%) ")])])]), _c('div', {
    staticClass: "ml-16 pl-16"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_vm._v("ドリンク")]), _c('div', {
    staticClass: "ml-12"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("drink_sales") || 0)) + " (" + _vm._s(_vm.getValue("drink_sales_percentage") || 0) + "%) ")])]), _c('div', {
    staticClass: "d-flex mt-6"
  }, [_c('div', [_vm._v("その他")]), _c('div', {
    staticClass: "ml-15"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("others_sales") || 0)) + " (" + _vm._s(_vm.getValue("others_sales_percentage") || 0) + "%) ")])])]), _c('div', {
    staticClass: "ml-16 pl-16"
  }, [_c('div', {
    staticClass: "d-flex mt-n8"
  }, [_c('div', [_vm._v("デリバリー")]), _c('div', {
    staticClass: "ml-8"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.getValue("delivery_sales") || 0)) + " (" + _vm._s(_vm.getValue("delivery_sales_percentage") || 0) + "%) ")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }