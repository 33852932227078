var render = function () {
  var _vm$ingredientsCost, _vm$ingredientsCost2, _vm$ingredientsCost3, _vm$ingredientsCost4, _vm$ingredientsCost5, _vm$ingredientsCost6, _vm$ingredientsCost7, _vm$ingredientsCost8, _vm$ingredientsCost9, _vm$ingredientsCost10, _vm$ingredientsCost11, _vm$ingredientsCost12, _vm$salesReport;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-1"
  }, [_c('div', {
    staticClass: "ingredient-cost-background"
  }, [_c('div', {
    staticClass: "ingredient-table"
  }, [_c('v-simple-table', {
    staticClass: "table-text"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, _vm._l(_vm.ingredientCostNames, function (item) {
    return _c('td', {
      key: item,
      staticClass: "font-12px table-column"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)])])], 1)]), _c('v-simple-table', {
    staticClass: "earning-result-table pb-2"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, [_c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s((_vm$ingredientsCost = _vm.ingredientsCost) === null || _vm$ingredientsCost === void 0 ? void 0 : _vm$ingredientsCost.foodstuff_cost_percentage) + "% ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s((_vm$ingredientsCost2 = _vm.ingredientsCost) === null || _vm$ingredientsCost2 === void 0 ? void 0 : _vm$ingredientsCost2.food_cost_percentage) + "% ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s((_vm$ingredientsCost3 = _vm.ingredientsCost) === null || _vm$ingredientsCost3 === void 0 ? void 0 : _vm$ingredientsCost3.drink_cost_percentage) + "% ")])])])]), _c('div', {
    staticClass: "ml-7"
  }), _c('div', {
    staticClass: "mt-5 pb-10 ml-4 d-flex align-center gray-text"
  }, [_c('div', {
    staticClass: "summary-section"
  }, [_c('div', {
    staticClass: "d-flex font-14px"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v(_vm._s(_vm.previousMonth) + "月の棚卸")]), _c('div', {
    staticClass: "ml-11"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost4 = _vm.ingredientsCost) === null || _vm$ingredientsCost4 === void 0 ? void 0 : _vm$ingredientsCost4.previous_month_inventory) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("フード")]), _c('div', {
    staticClass: "ml-16 pl-2"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost5 = _vm.ingredientsCost) === null || _vm$ingredientsCost5 === void 0 ? void 0 : _vm$ingredientsCost5.previous_month_food_inventory) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("ドリンク")]), _c('div', {
    staticClass: "ml-15"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost6 = _vm.ingredientsCost) === null || _vm$ingredientsCost6 === void 0 ? void 0 : _vm$ingredientsCost6.previous_month_drink_inventory) || 0)) + " ")])])]), _c('div', {
    staticClass: "ml-16 pl-16 summary-section"
  }, [_c('div', {
    staticClass: "d-flex font-14px"
  }, [_c('div', [_vm._v(_vm._s(_vm.currentMonth) + "月の仕入")]), _c('div', {
    staticClass: "ml-12"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost7 = _vm.ingredientsCost) === null || _vm$ingredientsCost7 === void 0 ? void 0 : _vm$ingredientsCost7.foodstuff_cost) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', [_vm._v("フード")]), _c('div', {
    staticClass: "ml-16 pl-3"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost8 = _vm.ingredientsCost) === null || _vm$ingredientsCost8 === void 0 ? void 0 : _vm$ingredientsCost8.food_cost) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', [_vm._v("ドリンク")]), _c('div', {
    staticClass: "ml-16"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost9 = _vm.ingredientsCost) === null || _vm$ingredientsCost9 === void 0 ? void 0 : _vm$ingredientsCost9.drink_cost) || 0)) + " ")])])]), _c('div', {
    staticClass: "ml-16 pl-16 font-14px total-section"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_vm._v(_vm._s(_vm.currentMonth) + "月の食材原価")]), _c('div', {
    staticClass: "ml-8"
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.totalIngredientsCost)))])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', [_vm._v("フード")]), _c('div', {
    staticClass: "ml-16 pl-6"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.totalFoodIngredientsCost)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', [_vm._v("ドリンク")]), _c('div', {
    staticClass: "ml-16 pl-3"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.totalDrinkIngredientsCost)) + " ")])])])]), _c('div', {
    staticClass: "mt-5 pb-10 ml-4 d-flex align-center gray-text"
  }, [_c('div', {
    staticClass: "summary-section"
  }, [_c('div', {
    staticClass: "d-flex font-14px "
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v(_vm._s(_vm.currentMonth) + "月の棚卸")]), _c('div', {
    staticClass: "ml-11"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost10 = _vm.ingredientsCost) === null || _vm$ingredientsCost10 === void 0 ? void 0 : _vm$ingredientsCost10.current_month_inventory) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("フード")]), _c('div', {
    staticClass: "ml-16 pl-2"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost11 = _vm.ingredientsCost) === null || _vm$ingredientsCost11 === void 0 ? void 0 : _vm$ingredientsCost11.current_month_food_inventory) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("ドリンク")]), _c('div', {
    staticClass: "ml-15"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$ingredientsCost12 = _vm.ingredientsCost) === null || _vm$ingredientsCost12 === void 0 ? void 0 : _vm$ingredientsCost12.current_month_drink_inventory) || 0)) + " ")])])]), _c('div', {
    staticClass: "ml-16 pl-16 summary-section"
  }, [_c('div', {
    staticClass: "d-flex font-14px"
  }, [_c('div', [_vm._v(_vm._s(_vm.currentMonth) + "月の売上")]), _c('div', {
    staticClass: "ml-12"
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(((_vm$salesReport = _vm.salesReport) === null || _vm$salesReport === void 0 ? void 0 : _vm$salesReport.sales) || 0)))])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', [_vm._v("フード")]), _c('div', {
    staticClass: "ml-16 pl-3"
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.foodSale || 0)))])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', [_vm._v("ドリンク")]), _c('div', {
    staticClass: "ml-16"
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.drinkSale || 0)))])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }