<template>
  <!-- earnings -->
  <div class="earnings-table">
    <v-simple-table class="table-text">
      <tbody>
        <tr class="text-center">
          <td
            v-for="item in tableHeader"
            :key="item"
            class="font-12px table-column"
          >
            {{ item }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table class="earning-result-table pb-2">
      <tbody>
        <tr class="text-center">
          <td class="result-column">
            {{ getShopMonthlyReport?.business_days }}日
          </td>
          <td class="result-column">
            {{ getValue("number_of_total_customer") || 0 }}人
          </td>
          <td class="result-column">
            {{ getValue("number_of_customer_store") || 0 }}人
          </td>
          <td class="result-column">
            {{ getValue("number_of_total_group") || 0 }}組
          </td>
          <td class="result-column">
            {{ getValue("number_of_group_store") || 0 }}組
          </td>
          <td class="result-column">
            {{ getValue("average_customer_spend") || 0 | formatMoney }}
          </td>
          <td class="result-column">
            {{ getValue("average_in_shop_customer_spend") || 0 | formatMoney }}
          </td>
          <td class="result-column">
            {{ getValue("sales") || 0 | formatMoney }}
          </td>
          <template v-if="selectedCategory !== 'all'">
            <td class="result-column">
              {{ getValue("sunny_days") || 0 }}
            </td>
            <td class="result-column">
              {{ getValue("cloudy_days") || 0 }}
            </td>
            <td class="result-column">
              {{ getValue("rainy_days") || 0 }}
            </td>
          </template>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="earnings-card mt-6 ml-4 d-flex align-center font-14px">
      <div class="ml-3 text-light-grey">売上</div>
      <div class="ml-16 pl-3 text-light-grey">
        {{ getValue("sales") || 0 | formatMoney }}
      </div>
      <template v-if="selectedCategory === 'all'">
        <div class="ml-14 light-gray-text">( 現金売上 )</div>
        <div class="ml-5 light-gray-text">
          {{ salesReport?.cash_sales || 0 | formatMoney }}
        </div>
      </template>
    </div>
    <div class="mt-5 pb-10 ml-4 d-flex align-center font-14px text-light-grey">
      <div>
        <div class="d-flex">
          <div class="ml-3">フード</div>
          <div class="ml-15">
            {{ getValue("food_sales") || 0 | formatMoney }} ({{
              getValue("food_sales_percentage") || 0
            }}%)
          </div>
        </div>
        <div class="d-flex mt-6">
          <div class="ml-3">テイクアウト</div>
          <div class="ml-5">
            {{ getValue("takeout_unit_sales") || 0 | formatMoney }} ({{
              getValue("takeout_sales_percentage") || 0
            }}%)
          </div>
        </div>
      </div>
      <div class="ml-16 pl-16">
        <div class="d-flex">
          <div>ドリンク</div>
          <div class="ml-12">
            {{ getValue("drink_sales") || 0 | formatMoney }} ({{
              getValue("drink_sales_percentage") || 0
            }}%)
          </div>
        </div>
        <div class="d-flex mt-6">
          <div>その他</div>
          <div class="ml-15">
            {{ getValue("others_sales") || 0 | formatMoney }} ({{
              getValue("others_sales_percentage") || 0
            }}%)
          </div>
        </div>
      </div>
      <div class="ml-16 pl-16">
        <div class="d-flex mt-n8">
          <div>デリバリー</div>
          <div class="ml-8">
            {{ getValue("delivery_sales") || 0 | formatMoney }} ({{
              getValue("delivery_sales_percentage") || 0
            }}%)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Sales",
  props: {
    selectedCategory: {
      type: String,
      default: "all",
      required: true
    }
  },
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      if (value === -1) return "￥--";
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters(["getShopMonthlyReport"]),
    salesReport() {
      return this.getShopMonthlyReport?.cumulated_sales;
    },
    tableHeader() {
      if (this.selectedCategory === "all")
        return this.earningNames.slice(0, -3);
      return this.earningNames;
    }
  },
  data() {
    return {
      earningNames: [
        "日数",
        "総客数",
        "店内利用客数",
        "総組数",
        "店内利用組数",
        "客単価",
        "店内利用客単価",
        "平均売上",
        "晴れ",
        "曇り",
        "雨"
      ]
    };
  },
  methods: {
    getValue(field) {
      if (this.selectedCategory === "all") return this.salesReport?.[field];
      else return this.salesReport?.[`${this.selectedCategory}_${field}`];
    }
  }
};
</script>

<style lang="scss" scoped>
.earnings-card {
  height: 34px;
  width: 96%;
  background-color: #f6f7ff;
}
.light-gray-text {
  color: #9b98a9;
}
.earnings-table {
  .table-text {
    height: 37px;
    background-color: #f3f2f7;
    text-align: center !important;
    border-bottom: 2px solid #ebe9f1 !important;
    border-top: 2px solid #ebe9f1 !important;
    color: #6e6b7b !important;
    tbody {
      tr:hover {
        background-color: transparent !important;
        cursor: default;
      }
    }
  }
  .table-column {
    width: 9.1% !important;
    align-items: center !important;
    height: 34px !important;
  }
  td:nth-child(7) {
    width: 11% !important;
  }
}

.earning-result-table {
  .result-column {
    width: 9.1% !important;
    height: 48px !important;
    color: #6e6b7b !important;
    font-size: 12px;
  }
  td:nth-child(7) {
    width: 11% !important;
  }
}
</style>
