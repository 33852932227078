<template>
  <div class="font-Roboto">
    <Parent />
    <v-card class="mt-3">
      <div class="d-flex pt-6 pl-4">
        <div class="d-flex">
          <v-btn icon small @click="changeMonth('prev')"
            ><v-icon>$purpleArrowLeft</v-icon></v-btn
          >
          <div class="font-18px mx-5 primary--text">
            {{ dateSlashFormatted }}
          </div>
          <v-btn icon small @click="changeMonth('next')"
            ><v-icon>$purpleArrowRight</v-icon></v-btn
          >
        </div>
      </div>
      <!-- Month Data -->
      <div class="mt-5 header-table">
        <v-simple-table class="table-text">
          <tbody>
            <tr class="text-center">
              <td
                v-for="item in monthlyDataNames"
                :key="item"
                class="font-12px table-column"
              >
                {{ item }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table class="result-table pb-2">
          <tbody>
            <tr class="text-center">
              <td class="font-12px result-column">
                {{ monthlyReportSummary?.sales?.sales || 0 | formatMoney }}
              </td>
              <td class="font-12px result-column">
                {{ monthlyReportSummary?.sales?.goal || 0 | formatMoney }}
              </td>
              <td class="font-12px result-column">
                <div
                  :class="
                    monthlyReportSummary?.sales?.goal_percentage > 100
                      ? 'text-green'
                      : 'text-red'
                  "
                >
                  {{ monthlyReportSummary?.sales?.goal_percentage }} %
                </div>
              </td>
              <td class="font-12px result-column">
                {{ monthlyReportSummary?.sales?.cash_sales || 0 | formatMoney }}
              </td>
              <td class="font-12px result-column">
                {{ monthlyReportSummary?.costs?.costs || 0 | formatMoney }}
              </td>
              <td class="font-12px result-column">
                {{ monthlyReportSummary?.profit_or_loss || 0 | formatMoney }}
              </td>
              <td class="font-12px result-column">
                <div :class="monthlyReportSummary?.costs?.food_cost_percentage > 100 ? 'text-green' : 'text-red'">
                  {{ monthlyReportSummary?.costs?.food_cost_percentage }}%
                </div>
                <div class="font-12px text-bbb">
                  {{ monthlyReportSummary?.costs?.foodstuff_cost | formatMoney }}
                </div>
              </td>
              <td class="font-12px result-column">
                <div :class="monthlyReportSummary?.costs?.labor_cost_percentage > 100 ? 'text-green' : 'text-red'">
                  {{ monthlyReportSummary?.costs?.labor_cost_percentage }}%
                </div>
                <div class="font-12px text-bbb">
                  {{ monthlyReportSummary?.costs?.labor_cost | formatMoney }}
                </div>
              </td>
              <td class="font-12px result-column">
                {{ monthlyReportSummary?.sales?.average_customer_spend || 0 | formatMoney }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card>
    <!-- Earnings -->
    <v-card class="mt-5">
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <div class="pt-5 ml-4 pb-6">
            <v-card color="#525252" depressed flat height="29px" width="52px">
              <div class="font-16px white--text pl-2 pt-1">
                売上
              </div>
            </v-card>
          </div>
          <div class="pt-5 ml-3">
            <v-btn-toggle
              v-model="selectedSalesTypeToggle"
              borderless
              dense
              class="d-flex toggle-button"
            >
              <v-btn
                v-for="(item, index) in salesTypeToggleButtonNames"
                :key="index"
                height="26px"
                class="text-a1 selected-btn mx-3"
                color="transparent"
                rounded
                :value="item.value"
              >
                {{ item.text }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
        <div class="mt-5 mr-7">
          <v-icon
            v-if="showEarningsTable"
            @click="showEarningsTable = !showEarningsTable"
            size="12"
            >$arrowUp</v-icon
          >
          <v-icon
            v-if="!showEarningsTable"
            @click="showEarningsTable = !showEarningsTable"
            size="12"
            >$arrowDown</v-icon
          >
        </div>
      </div>
      <Sales
        v-if="showEarningsTable"
        :selected-category="selectedSalesTypeToggle"
      />
    </v-card>
    <!-- Cost -->
    <v-card class="mt-5">
      <div class="d-flex justify-space-between">
        <div class="d-flex pt-5 ml-4 pb-5">
          <v-card color="#525252" depressed flat height="29px" width="64px">
            <div class="font-16px white--text pl-2 pt-1">
              コスト
            </div>
          </v-card>
        </div>
        <div class="mt-6 mr-7">
          <v-icon
            v-if="showCostTable"
            @click="showCostTable = !showCostTable"
            size="12"
            >$arrowUp</v-icon
          >
          <v-icon
            v-if="!showCostTable"
            @click="showCostTable = !showCostTable"
            size="12"
            >$arrowDown</v-icon
          >
        </div>
      </div>
      <template v-if="showCostTable">
        <div class="gray-divider mt-0"></div>
        <Cost />
      </template>
    </v-card>
    <!-- Ingredient Cost Rate -->
    <v-card class="mt-5">
      <div class="d-flex justify-space-between">
        <div class="d-flex pt-5 ml-4 pb-5">
          <v-card color="#525252" depressed flat height="29px" width="96px">
            <div class="font-16px white--text pl-2 pt-1">
              食材原価率
            </div>
          </v-card>
        </div>
        <div class="mt-6 mr-7">
          <v-icon
            v-if="showIngredientCostRate"
            @click="showIngredientCostRate = !showIngredientCostRate"
            size="12"
            >$arrowUp</v-icon
          >
          <v-icon
            v-if="!showIngredientCostRate"
            @click="showIngredientCostRate = !showIngredientCostRate"
            size="12"
            >$arrowDown</v-icon
          >
        </div>
      </div>
      <IngredientCostRate v-if="showIngredientCostRate" />
    </v-card>
  </div>
</template>

<script>
import Parent from "@/views/admin/Store/Index";
import Cost from "@/components/admin/store/monthly-report-detail/Cost";
import Sales from "@/components/admin/store/monthly-report-detail/Sales";
import IngredientCostRate from "@/components/admin/store/monthly-report-detail/IngredientCostRate";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";

export default {
  name: "MonthlyReportDetail",
  components: { Parent, Sales, Cost, IngredientCostRate },
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      if (value === -1) return "￥--";
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters(["getShopMonthlyReport"]),
    reportDate() {
      return this.$route.params.month;
    },
    dateFullFormatted() {
      return this.currentDate?.format("YYYY-MM-DD");
    },
    dateDashFormatted() {
      return this.currentDate?.format("YYYY-MM");
    },
    dateSlashFormatted() {
      return this.currentDate?.format("YYYY/MM");
    },
    monthlySales() {
      return this.getShopMonthlyReport.cumulated_sales;
    },
    monthlyCosts() {
      return this.getShopMonthlyReport.cumulated_costs;
    },
    monthlyReportSummary() {
      return {
        sales: this.monthlySales,
        costs: this.monthlyCosts,
        profit_or_loss: (this.monthlySales?.sales ?? 0) - (this.monthlyCosts?.costs ?? 0) || null
      };
    }
  },
  data() {
    return {
      currentDate: dayjs(),
      currentFiscalMonth: "2021/09",
      showEarningsTable: true,
      showCostTable: true,
      showIngredientCostRate: true,
      selectedSalesTypeToggle: "all",
      salesTypeToggleButtonNames: [
        { value: "all", text: "当日" },
        { value: "lunch", text: "ランチ" },
        { value: "dinner", text: "ディナー" }
      ],
      monthlyDataNames: [
        "売上実績",
        "売上目標",
        "達成率 (%)",
        "現金売上",
        "コスト",
        "損益",
        "食材原価率",
        "人件費率",
        "客単価"
      ],
      monthReport: [
        {
          id: 1,
          month: "2021/09",
          salesResult: "¥ 1,765,259",
          salesTarget: "¥ 1,900,000",
          achievementRate: 92.9,
          cashSales: "¥ 1,900,000",
          cost: "¥ 1,671,882",
          profitAndLoss: "¥ 1,671,882",
          ingredientCost: "¥ 414,836",
          ingredientCostRate: "23.5%",
          laborCost: "¥ 432,488",
          laborCostRate: "24.5%",
          customerUnitPrice: "¥ 1,800"
        }
      ],
      earningsReport: {
        id: 1,
        days: "26日",
        totalCustomers: "1133人",
        customersInStore: "1133人",
        totalPairCustomers: "111組",
        groupCustomersInStore: "111組",
        customerUnitPrice: "￥2,946",
        customerUnitPriceInStore: "￥2,946",
        averageSales: "￥1,900,000",
        sunny: "15日",
        cloudy: "10日",
        rain: "5日"
      }
    };
  },
  mounted() {
    if (!this.reportDate) {
      this.reloadWithChangedDate(this.currentDate.format("YYYY-MM-DD"));
    } else {
      this.currentDate = dayjs(this.reportDate);
    }
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      await this.$store.dispatch("SHOP_MONTHLY_REPORT", {
        shop_id: this.$route.params.shop_id,
        month: this.dateFullFormatted
      });
    },
    reloadWithChangedDate(date) {
      this.$router.replace({
        name: "MonthlyReportDetail",
        params: {
          shop_id: this.$route.params.shop_id,
          month: date
        }
      });
    },
    changeMonth(direction) {
      if (direction === "next")
        this.currentDate = this.currentDate.add(1, "month");
      else this.currentDate = this.currentDate.subtract(1, "month");

      this.reloadWithChangedDate(this.dateDashFormatted);
      this.getDataFromApi();
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
