<template>
  <div class="mt-1">
    <div class="cost-card mt-6 ml-4 d-flex align-center font-14px">
      <div class="ml-3  text-light-grey">累計コスト</div>
      <div class="ml-8 pl-2  text-light-grey">
        {{ costsReport?.costs || 0 | formatMoney }}
      </div>
    </div>
    <div class="mt-5 pb-10 ml-4 d-flex align-center  text-light-grey">
      <div>
        <div class="d-flex font-14px ">
          <div class="ml-3">人件費(L)</div>
          <div class="ml-11">
            {{ costsReport?.labor_cost || 0 | formatMoney }} ({{
              costsReport?.labor_cost_percentage || 0
            }}%)
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div class="ml-3">正社員</div>
          <div class="ml-15 pl-2">
            {{ costsReport?.labor_cost_regular_employees || 0 | formatMoney }}
            ({{ costsReport?.labor_cost_regular_employees_percentage || 0 }}%)
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div class="ml-3">アルバイト</div>
          <div class="ml-11">
            {{ costsReport?.part_time_job_payments || 0 | formatMoney }} ({{
              costsReport?.part_time_job_payments_percentage || 0
            }}%)
          </div>
        </div>
      </div>
      <div class="ml-16 pl-16">
        <div class="d-flex font-14px">
          <div>食材費(F)</div>
          <div class="ml-12">
            {{ costsReport?.foodstuff_cost || 0 | formatMoney }} ({{
              costsReport?.foodstuff_cost_percentage || 0
            }}%)
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div>フード</div>
          <div class="ml-16 pl-2">
            {{ costsReport?.food_cost || 0 | formatMoney }} ({{
              costsReport?.food_cost_percentage || 0
            }}%)
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div>ドリンク</div>
          <div class="ml-15">
            {{ costsReport?.drink_cost || 0 | formatMoney }} ({{
              costsReport?.drink_cost_percentage || 0
            }}%)
          </div>
        </div>
      </div>
      <div class="ml-16 pl-16 font-14px">
        <div class="d-flex mt-n9">
          <div>家賃(R)</div>
          <div class="ml-10">
            {{ costsReport?.rent_cost || 0 | formatMoney }} ({{
              costsReport?.rent_cost_percentage || 0
            }}%)
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex ml-7  text-light-grey font-14px">
      <div>
        プラットフォーム手数料(P)
      </div>
      <div class="d-flex delivery">
        <div>その他(O)</div>
        <div class="ml-10">
          {{ costsReport?.others_cost || 0 | formatMoney }} ({{
            costsReport?.others_cost_percentage || 0
          }}%)
        </div>
      </div>
    </div>
    <div class=" text-light-grey font-14px  platform-fee">
      {{ costsReport?.platform_cost || 0 | formatMoney }} ({{
        costsReport?.platform_cost_percentage || 0
      }}%)
    </div>
    <div class="cost-card mt-6 ml-4 d-flex align-center font-14px">
      <div class="ml-3  text-light-grey">主要取引先</div>
    </div>
    <div class="mt-5 pb-10 ml-4 d-flex align-center font-14px  text-light-grey">
      <v-row class="ml-1">
        <v-col cols="8">
          <v-row>
            <template v-for="(partner, i) in partners">
              <v-col cols="2" :key="`partner-name-${i}`">
                {{ partner?.partner?.business_partner_name || "その他" }}
              </v-col>
              <v-col cols="2" :key="`partner-cost-${i}`">
                {{ partner?.total_purchase || 0 | formatMoney }} ({{
                  partner?.percentage || 0
                }}%)
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Cost",
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      if (value === -1) return "￥--";
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters(["getShopMonthlyReport"]),
    costsReport() {
      return this.getShopMonthlyReport?.cumulated_costs;
    },
    partners() {
      return [
        ...this.getShopMonthlyReport?.top_5_partners,
        { ...this.getShopMonthlyReport?.other_partners }
      ];
    }
  },
  data() {
    return {};
  },
  methods: {
    getPartnerDataAtIndex(index, field) {
      return this.getShopMonthlyReport.top_5_partners?.[index]?.[field];
    }
  }
};
</script>

<style lang="scss" scoped>
.cost-card {
  height: 34px;
  width: 96%;
  background-color: #f6f7ff;
}
.light-gray-text {
  color: #9b98a9;
}
.delivery {
  margin-left: 170px !important;
}
.platform-fee {
  margin-left: 153px !important;
}
</style>
