<template>
  <div class="mt-1">
    <div class="ingredient-cost-background">
      <div class="ingredient-table">
        <v-simple-table class="table-text">
          <tbody>
            <tr class="text-center">
              <td
                v-for="item in ingredientCostNames"
                :key="item"
                class="font-12px table-column"
              >
                {{ item }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
    <v-simple-table class="earning-result-table pb-2">
      <tbody>
        <tr class="text-center">
          <td class="result-column">
            {{ ingredientsCost?.foodstuff_cost_percentage }}%
          </td>
          <td class="result-column">
            {{ ingredientsCost?.food_cost_percentage }}%
          </td>
          <td class="result-column">
            {{ ingredientsCost?.drink_cost_percentage }}%
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="ml-7"></div>

    <div class="mt-5 pb-10 ml-4 d-flex align-center gray-text">
      <div class="summary-section">
        <div class="d-flex font-14px">
          <div class="ml-3">{{ previousMonth }}月の棚卸</div>
          <div class="ml-11">
            {{ ingredientsCost?.previous_month_inventory || 0 | formatMoney }}
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div class="ml-3">フード</div>
          <div class="ml-16 pl-2">
            {{
              ingredientsCost?.previous_month_food_inventory || 0 | formatMoney
            }}
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div class="ml-3">ドリンク</div>
          <div class="ml-15">
            {{
              ingredientsCost?.previous_month_drink_inventory || 0 | formatMoney
            }}
          </div>
        </div>
      </div>
      <div class="ml-16 pl-16 summary-section">
        <div class="d-flex font-14px">
          <div>{{ currentMonth }}月の仕入</div>
          <div class="ml-12">
            {{ ingredientsCost?.foodstuff_cost || 0 | formatMoney }}
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div>フード</div>
          <div class="ml-16 pl-3">
            {{ ingredientsCost?.food_cost || 0 | formatMoney }}
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div>ドリンク</div>
          <div class="ml-16">
            {{ ingredientsCost?.drink_cost || 0 | formatMoney }}
          </div>
        </div>
      </div>
      <div class="ml-16 pl-16 font-14px total-section">
        <div class="d-flex">
          <div>{{ currentMonth }}月の食材原価</div>
          <div class="ml-8">{{ totalIngredientsCost | formatMoney }}</div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div>フード</div>
          <div class="ml-16 pl-6">
            {{ totalFoodIngredientsCost | formatMoney }}
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div>ドリンク</div>
          <div class="ml-16 pl-3">
            {{ totalDrinkIngredientsCost | formatMoney }}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 pb-10 ml-4 d-flex align-center gray-text">
      <div class="summary-section">
        <div class="d-flex font-14px ">
          <div class="ml-3">{{ currentMonth }}月の棚卸</div>
          <div class="ml-11">
            {{ ingredientsCost?.current_month_inventory || 0 | formatMoney }}
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div class="ml-3">フード</div>
          <div class="ml-16 pl-2">
            {{
              ingredientsCost?.current_month_food_inventory || 0 | formatMoney
            }}
          </div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div class="ml-3">ドリンク</div>
          <div class="ml-15">
            {{
              ingredientsCost?.current_month_drink_inventory || 0 | formatMoney
            }}
          </div>
        </div>
      </div>
      <div class="ml-16 pl-16 summary-section">
        <div class="d-flex font-14px">
          <div>{{ currentMonth }}月の売上</div>
          <div class="ml-12">{{ salesReport?.sales || 0 | formatMoney }}</div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div>フード</div>
          <div class="ml-16 pl-3">{{ foodSale || 0 | formatMoney }}</div>
        </div>
        <div class="d-flex mt-2 font-12px">
          <div>ドリンク</div>
          <div class="ml-16">{{ drinkSale || 0 | formatMoney }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "IngredientCostRate",
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      if (value === -1) return "￥--";
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters(["getShopMonthlyReport"]),
    ingredientsCost() {
      return this.getShopMonthlyReport?.ingredients_costs;
    },
    reportDate() {
      return dayjs(`${this.$route.params.month}-01`);
    },
    currentMonth() {
      return this.reportDate.format("M");
    },
    previousMonth() {
      return this.reportDate.subtract(1, "month").format("M");
    },
    salesReport() {
      return this.getShopMonthlyReport?.cumulated_sales;
    },
    foodSale() {
      return (
        this.salesReport?.food_sales +
        this.salesReport?.takeout_unit_sales +
        this.salesReport?.delivery_sales +
        this.salesReport?.others_sales
      );
    },
    drinkSale() {
      return this.salesReport?.drink_sales;
    },
    totalIngredientsCost() {
      return (
        (this.ingredientsCost?.previous_month_inventory || 0) +
        (this.ingredientsCost?.foodstuff_cost || 0) -
        (this.ingredientsCost?.current_month_inventory || 0)
      );
    },
    totalFoodIngredientsCost() {
      return (
        (this.ingredientsCost?.previous_month_food_inventory || 0) +
        (this.ingredientsCost?.food_cost || 0) -
        (this.ingredientsCost?.current_month_food_inventory || 0)
      );
    },
    totalDrinkIngredientsCost() {
      return (
        (this.ingredientsCost?.previous_month_drink_inventory || 0) +
        (this.ingredientsCost?.drink_cost || 0) -
        (this.ingredientsCost?.current_month_drink_inventory || 0)
      );
    }
  },
  data() {
    return {
      ingredientCostNames: ["食材原価率", "フード原価率", "ドリンク原価率"]
    };
  }
};
</script>

<style lang="scss" scoped>
.summary-section {
  width: 250px;
}
.total-section {
  width: 270px;
}
.gray-text {
  color: #6e6b7b;
}
.ingredient-cost-background {
  height: 39px;
  width: 100%;
  background-color: #f3f2f7;
  border-bottom: 2px solid #ebe9f1 !important;
  border-top: 2px solid #ebe9f1 !important;
}
.ingredient-table {
  width: 400px !important;
  tbody {
    tr:hover {
      background-color: transparent !important;
      cursor: default;
    }
  }
  .table-text {
    height: 37px;
    background-color: #f3f2f7;
    text-align: center !important;
    border-bottom: 2px solid #ebe9f1 !important;
    color: #6e6b7b !important;
  }
  .table-column {
    width: 33% !important;
    align-items: center !important;
    height: 34px !important;
  }
}

.earning-result-table {
  width: 400px !important;
  .result-column {
    height: 39px !important;
    color: #6e6b7b !important;
    font-size: 12px;
    width: 20px;
  }
}
</style>
