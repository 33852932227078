var render = function () {
  var _vm$costsReport, _vm$costsReport2, _vm$costsReport3, _vm$costsReport4, _vm$costsReport5, _vm$costsReport6, _vm$costsReport7, _vm$costsReport8, _vm$costsReport9, _vm$costsReport10, _vm$costsReport11, _vm$costsReport12, _vm$costsReport13, _vm$costsReport14, _vm$costsReport15, _vm$costsReport16, _vm$costsReport17, _vm$costsReport18, _vm$costsReport19;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-1"
  }, [_c('div', {
    staticClass: "cost-card mt-6 ml-4 d-flex align-center font-14px"
  }, [_c('div', {
    staticClass: "ml-3  text-light-grey"
  }, [_vm._v("累計コスト")]), _c('div', {
    staticClass: "ml-8 pl-2  text-light-grey"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport = _vm.costsReport) === null || _vm$costsReport === void 0 ? void 0 : _vm$costsReport.costs) || 0)) + " ")])]), _c('div', {
    staticClass: "mt-5 pb-10 ml-4 d-flex align-center  text-light-grey"
  }, [_c('div', [_c('div', {
    staticClass: "d-flex font-14px "
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("人件費(L)")]), _c('div', {
    staticClass: "ml-11"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport2 = _vm.costsReport) === null || _vm$costsReport2 === void 0 ? void 0 : _vm$costsReport2.labor_cost) || 0)) + " (" + _vm._s(((_vm$costsReport3 = _vm.costsReport) === null || _vm$costsReport3 === void 0 ? void 0 : _vm$costsReport3.labor_cost_percentage) || 0) + "%) ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("正社員")]), _c('div', {
    staticClass: "ml-15 pl-2"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport4 = _vm.costsReport) === null || _vm$costsReport4 === void 0 ? void 0 : _vm$costsReport4.labor_cost_regular_employees) || 0)) + " (" + _vm._s(((_vm$costsReport5 = _vm.costsReport) === null || _vm$costsReport5 === void 0 ? void 0 : _vm$costsReport5.labor_cost_regular_employees_percentage) || 0) + "%) ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("アルバイト")]), _c('div', {
    staticClass: "ml-11"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport6 = _vm.costsReport) === null || _vm$costsReport6 === void 0 ? void 0 : _vm$costsReport6.part_time_job_payments) || 0)) + " (" + _vm._s(((_vm$costsReport7 = _vm.costsReport) === null || _vm$costsReport7 === void 0 ? void 0 : _vm$costsReport7.part_time_job_payments_percentage) || 0) + "%) ")])])]), _c('div', {
    staticClass: "ml-16 pl-16"
  }, [_c('div', {
    staticClass: "d-flex font-14px"
  }, [_c('div', [_vm._v("食材費(F)")]), _c('div', {
    staticClass: "ml-12"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport8 = _vm.costsReport) === null || _vm$costsReport8 === void 0 ? void 0 : _vm$costsReport8.foodstuff_cost) || 0)) + " (" + _vm._s(((_vm$costsReport9 = _vm.costsReport) === null || _vm$costsReport9 === void 0 ? void 0 : _vm$costsReport9.foodstuff_cost_percentage) || 0) + "%) ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', [_vm._v("フード")]), _c('div', {
    staticClass: "ml-16 pl-2"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport10 = _vm.costsReport) === null || _vm$costsReport10 === void 0 ? void 0 : _vm$costsReport10.food_cost) || 0)) + " (" + _vm._s(((_vm$costsReport11 = _vm.costsReport) === null || _vm$costsReport11 === void 0 ? void 0 : _vm$costsReport11.food_cost_percentage) || 0) + "%) ")])]), _c('div', {
    staticClass: "d-flex mt-2 font-12px"
  }, [_c('div', [_vm._v("ドリンク")]), _c('div', {
    staticClass: "ml-15"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport12 = _vm.costsReport) === null || _vm$costsReport12 === void 0 ? void 0 : _vm$costsReport12.drink_cost) || 0)) + " (" + _vm._s(((_vm$costsReport13 = _vm.costsReport) === null || _vm$costsReport13 === void 0 ? void 0 : _vm$costsReport13.drink_cost_percentage) || 0) + "%) ")])])]), _c('div', {
    staticClass: "ml-16 pl-16 font-14px"
  }, [_c('div', {
    staticClass: "d-flex mt-n9"
  }, [_c('div', [_vm._v("家賃(R)")]), _c('div', {
    staticClass: "ml-10"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport14 = _vm.costsReport) === null || _vm$costsReport14 === void 0 ? void 0 : _vm$costsReport14.rent_cost) || 0)) + " (" + _vm._s(((_vm$costsReport15 = _vm.costsReport) === null || _vm$costsReport15 === void 0 ? void 0 : _vm$costsReport15.rent_cost_percentage) || 0) + "%) ")])])])]), _c('div', {
    staticClass: "d-flex ml-7  text-light-grey font-14px"
  }, [_c('div', [_vm._v(" プラットフォーム手数料(P) ")]), _c('div', {
    staticClass: "d-flex delivery"
  }, [_c('div', [_vm._v("その他(O)")]), _c('div', {
    staticClass: "ml-10"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport16 = _vm.costsReport) === null || _vm$costsReport16 === void 0 ? void 0 : _vm$costsReport16.others_cost) || 0)) + " (" + _vm._s(((_vm$costsReport17 = _vm.costsReport) === null || _vm$costsReport17 === void 0 ? void 0 : _vm$costsReport17.others_cost_percentage) || 0) + "%) ")])])]), _c('div', {
    staticClass: " text-light-grey font-14px  platform-fee"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport18 = _vm.costsReport) === null || _vm$costsReport18 === void 0 ? void 0 : _vm$costsReport18.platform_cost) || 0)) + " (" + _vm._s(((_vm$costsReport19 = _vm.costsReport) === null || _vm$costsReport19 === void 0 ? void 0 : _vm$costsReport19.platform_cost_percentage) || 0) + "%) ")]), _vm._m(0), _c('div', {
    staticClass: "mt-5 pb-10 ml-4 d-flex align-center font-14px  text-light-grey"
  }, [_c('v-row', {
    staticClass: "ml-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-row', [_vm._l(_vm.partners, function (partner, i) {
    var _partner$partner;

    return [_c('v-col', {
      key: "partner-name-".concat(i),
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s((partner === null || partner === void 0 ? void 0 : (_partner$partner = partner.partner) === null || _partner$partner === void 0 ? void 0 : _partner$partner.business_partner_name) || "その他") + " ")]), _c('v-col', {
      key: "partner-cost-".concat(i),
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")((partner === null || partner === void 0 ? void 0 : partner.total_purchase) || 0)) + " (" + _vm._s((partner === null || partner === void 0 ? void 0 : partner.percentage) || 0) + "%) ")])];
  })], 2)], 1)], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cost-card mt-6 ml-4 d-flex align-center font-14px"
  }, [_c('div', {
    staticClass: "ml-3  text-light-grey"
  }, [_vm._v("主要取引先")])]);
}]

export { render, staticRenderFns }